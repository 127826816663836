import React from 'react';
import Spinner from '../clientAdmin/components/Spinner'
import CircleStarsIcon from './Icons/circle-stars'

function SignupSectionV2({
  disabled,
  subscribing,
  buttonColor,
  trialPeriod,
  styles,
  subtotal,
  isHalfVisible,
  isEmbed,
}) {
  const scrollToBottom = (e) => {
    e.preventDefault();
    // Using setTimeout to ensure this runs after any pending DOM updates
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
      });
    }, 0); // minimal timeout
  };

  const btnDisabled = isHalfVisible && disabled;

  return (
    <div className={`
      flex
      inset-x-0 bg-white shadow-custom
      shadow-md border-t border-t-gray-400 rounded-t-2xl
      flex-col bottom-0 sm:w-auto sm:relative w-full left-0 bg-white
      overflow-hidden z-10

      ${!isEmbed ? 'sm:shadow-none sm:border-none sm:p-0 sm:rounded-none fixed sm:flex fixed' : 'flex sm:p-0 shadow-none border-none rounded-none rounded-t-none'}
    `}
    >
      {!isEmbed && trialPeriod > 0 && isHalfVisible && (
        <div className="flex sm:hidden items-center justify-between bg-blue-200 py-[8px] px-[24px]">
          <div className="text-blacl-pearl font-semibold text-base gap-1 trial-period">
            {`Free for ${trialPeriod} day${trialPeriod > 1 ? 's' : ''}`}
          </div>
          <CircleStarsIcon />
        </div>
      )}
      <div className={`flex flex-col bg-white gap-2 sm:gap-0 w-full ${!isEmbed ? 'sm:p-0 p-4' : 'p-0'}`}>
        {!isEmbed && isHalfVisible && (
          <div className="sm:hidden flex justify-between gap-2 items-center">
            <div className="text-black-pearl font-semibold flex items-center font-sm">Total due today</div>
            <div className="text-black-pearl font-semibold flex items-center font-sm">{subtotal}</div>
          </div>
        )}
        <button
          {...((isEmbed || isHalfVisible)
            ? { type: 'submit' }
            : { onClick: scrollToBottom }
            )
          }
          type="submit"
          form="payment-form"
          disabled={btnDisabled}
          style={{ backgroundColor: buttonColor ?? 'initial' }}
          className={`
            ${styles.TierModal.payButton} bg-primary sm:w-full ${
            btnDisabled ? 'opacity-30' : ''
          }
          `}
        >
          {subscribing && <Spinner color="#FFFFFF" size={25} />}
          {!subscribing && !isHalfVisible ? 'Join now' : ''}
          {!subscribing && isHalfVisible && (trialPeriod > 0 ? 'Start Trial' : 'Join now')}
        </button>
      </div>
    </div>
  );
}

export default SignupSectionV2;
