import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

function LightboxGallery({
  assets, isOpen, initialIndex = 0, onClose, onSlideChange,
}) {
  const [currentSlide, setCurrentSlide] = useState(initialIndex);

  useEffect(() => {
    setCurrentSlide(initialIndex);
  }, [initialIndex]);

  useEffect(() => {
    if (onSlideChange) {
      onSlideChange(currentSlide);
    }
  }, [currentSlide, onSlideChange]);

  const handleCloseModal = () => {
    onClose && onClose();
  };

  const plusSlides = (n) => {
    setCurrentSlide((prev) => (prev + n + assets.length) % assets.length);
  };

  return (
    <div>
      {isOpen && (
        <div className="w-full fixed z-50 inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <span
            className="absolute top-5 right-5 text-white text-3xl cursor-pointer"
            style={{ zIndex: 99999 }}
            onClick={handleCloseModal}
          >
            &times;
          </span>
          <div className="relative w-full bg-transparent p-4">
            {assets.map((asset, index) => (
              <div
                key={asset.public_id}
                className={`${index === currentSlide ? 'block px-10' : 'hidden'} mySlides`}
              >
                <div className="text-gray-700 text-sm absolute top-0 left-0 p-2">
                  {index + 1}
                  {' '}
                  /
                  {assets.length}
                </div>
                {asset.type === 'image' ? (
                  <img src={asset.url} className="mx-auto" alt={asset.alt} />
                ) : asset.type === 'custom-video' ? (
                  <ReactPlayer url={asset.url} width="100%" height="100%" controls />
                ) : (
                  <video controls className="mx-auto">
                    <source src={asset.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}

            {/* Next/previous controls */}
            <a
              className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-2xl cursor-pointer p-4"
              onClick={() => plusSlides(-1)}
            >
              &#10094;
            </a>
            <a
              className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white text-2xl cursor-pointer p-4" onClick={() => plusSlides(1)}
            >
              &#10095;
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default LightboxGallery;
