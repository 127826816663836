import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from './EmblaCarouselArrowButtons';
import CustomLightbox from '../Lightbox';

function EmblaCarousel(props) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [startingPosition, setStartingPosition] = useState(0);
  const { assets, options, disableInteractions } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [playingStates, setPlayingStates] = useState([]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.reInit();
    }
    if (!assets) {
      return;
    }

    const newPlayingStates = assets.map(() => false);
    setPlayingStates(newPlayingStates)
  }, [assets, emblaApi]);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    // Stop playing the video that is being moved away from
    setPlayingStates(playingStates.map(() => false));
  }, [selectedIndex]);

  const handlePlay = (index) => {
    setPlayingStates((prev) => {
      const newPlayingStates = prev.map((playing, i) => (i === index));
      return newPlayingStates;
    });
  };

  const handleStop = () => {
    setPlayingStates((prev) => {
      const newPlayingStates = prev.map((playing, i) => false);
      return newPlayingStates;
    });
  };

  const onImageClick = (startingPosition) => {
    if (disableInteractions) return;
    setStartingPosition(startingPosition);
    setLightboxOpen(true);
  };

  const onLightboxClose = () => {
    setStartingPosition(0);
    setLightboxOpen(false);
  };

  if (!assets?.length) {
    return null;
  }

  return (
    <section className="embla">
      <div className="embla__viewport rounded-lg" ref={disableInteractions ? null : emblaRef}>
        <div className="embla__container">
          {assets.map((item, index) => {
            if (item.type === 'video' || item.type === 'custom-video') {
              return (
                <div
                  className="embla__slide w-full aspect-w-16 aspect-h-9 relative"
                  key={item.public_id}
                  tabIndex={0}
                  role="button"
                  onClick={(e) => {
                    if (disableInteractions) return;
                    handlePlay(index)
                  }}
                >
                  <ReactPlayer
                    onStart={() => handlePlay(index)}
                    onPlay={() => handlePlay(index)}
                    onPause={handleStop}
                    url={item.url}
                    stopOnUnmount
                    width="100%"
                    height="433px"
                    controls
                    playing={playingStates[index] || false}
                  />
                  {!playingStates[index] && (
                    <div
                      className="absolute top-0 left-0 w-full"
                      style={{ height: 'calc(100% - 48px)' }}
                    />
                  )}
                </div>
              )
            }

            return (
              <div
                className="embla__slide w-full aspect-w-16 aspect-h-9 relative"
                key={item.public_id}
                role="button"
                tabIndex={0}
                disabled={disableInteractions}
                onClick={() => onImageClick(index)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onImageClick(index);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onImageClick(index);
                  }
                }}
              >
                {item.type === 'image' && (
                  <img
                    src={item.url}
                    alt={`Slide ${index}`}
                    width="100%"
                    height="433px"
                    className="object-cover w-full"
                    style={{ height: '433px' }}
                  />
                )}
              </div>
            )
          })}
        </div>
      </div>

      <div className="px-2 mt-3">
        <div className="flex gap-4 w-full space-between items-center overflow-hidden">
          <PrevButton onClick={onPrevButtonClick} disabled={disableInteractions || prevBtnDisabled} />
          <div className="embla_thumbs gap-2 flex-1 snap-x space-between overflow-auto custom-scrollbar hidden md:flex">
            {assets.map((item, index) => (
              <DotButton
                key={index}
                className={'snap-center embla__thumb bg-black bg-opacity-20 items-center rounded-lg'.concat(
                  index === selectedIndex ? ' embla__thumb--selected' : '',
                )}
                onClick={() => onDotButtonClick(index)}
                disabled={disableInteractions}
              >
                <img
                  src={item.thumbnailUrl ?? item.url}
                  alt={`Thumbnail ${index}`}
                  className="object-cover w-full h-full"
                />
              </DotButton>
            ))}
          </div>
          <div className="embla__dots flex-1 flex w-full md:hidden">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                disabled={disableInteractions}
                className={'embla__dot'.concat(
                  index === selectedIndex ? ' embla__dot--selected' : '',
                )}
              />
            ))}
          </div>
          <NextButton
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled || disableInteractions}
          />
        </div>
      </div>

      <CustomLightbox
        assets={assets}
        isOpen={lightboxOpen}
        initialIndex={startingPosition}
        onClose={onLightboxClose}
      />
    </section>
  );
}

export default EmblaCarousel;
