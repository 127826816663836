/* eslint-disable no-lonely-if */
/* eslint-disable space-before-blocks */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'
import lifecycle from 'react-pure-lifecycle'
import numbro from 'numbro'

import { Elements } from '@stripe/react-stripe-js';

import InjectedCheckoutForm from './CheckoutFormElements';

import { getFormattedPrices } from './../utils'

import {
  validateAndPostSubscriptionElements,
  validateFormOnly,
  toggleCouponField,
  validateCoupon,
  setStripe,
} from '../client/subscribe/actions'

import WelcomeModalSCA from './WelcomeModalSCA'
import ErrorModal from './ErrorModal'
import { getNumber, formatPrice, getDiscountedAmount } from '../utils'

class MyStoreCheckout extends React.Component {
  render() {
    const stripe =
    this.props.stripe?._apiKey && this.props.stripe?._apiKey !== "undefined"
      ? this.props.stripe
      : null
    return (
      <Elements stripe={stripe}>
        <InjectedCheckoutForm {...this.props} />
      </Elements>
    );
  }
}

const makePeriodSwitchHandler = (period) => (event) => {
  event.preventDefault()
  store.dispatch({ type: period })
  return false
}

const submitForm = (field) => (event) => {
  //  console.log("props", this.props);
  event.preventDefault()

  const affiliate = document.URL.split('?id=')[1]

  if (typeof clicky !== 'undefined') {
    clicky.goal('paidInviteSignup')
  }


  if(window.lp_event) window.lp_event('initiate_checkout')
  
  
  const form = event.target.form

  const validateForm = field ? validateFormOnly : validateAndPostSubscriptionElements

  store.dispatch(validateForm({
    email: form.email.value,
    // cardNumber: form.cardNumber.value,
    // date: form.date.value,
    // cvc: form.cvc.value,
    affiliate,
    formattedPrices: this.props.formattedPrices,
  }, field))

  return false
}

const componentDidMount = (props) => {
  if (window.stripe) {
    // console.log('setStripe', window.stripe);

    store.dispatch(setStripe(window.stripe));
  } else {
    // console.log('setStripe', null);

    // set to null for SSR
    store.dispatch(setStripe(null));
  }
  // console.log("new state",store.getState());

  if (props.coupon) {
    store.dispatch(toggleCouponField(true))
    store.dispatch(validateCoupon(props.coupon))
  }


}

const methods = {
  componentDidMount,
}

const StripeFormElements = ({
  preview,
  styles,
  url,
  price,
  priceYearly,
  yearlySelected,
  currency = 'usd',
  errors,
  period,
  trialPeriod,
  openModal,
  modalStyles,
  channel,
  group,
  subscribing,
  couponValidating,
  buttonColor,
  textColor,
  backgroundColor,
  status,
  alternatePayment,
  alternatePaymentAmount,
  joiningPayment,
  joiningPaymentAmount,
  waiveJoiningFeeForYearlyOption,
  convertCode,
  couponsEnabled,
  requireNameEnabled,
  requireAddressEnabled,
  customSetting1Enabled,
  customSetting2Enabled,
  customSetting3Enabled,
  verifyEmailEnabled,
  useOauthEnabled,
  couponFieldVisible,
  couponValid,
  couponId,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
  couponSubmitted,
  couponData,
  customTOS,
  displayName,
  couponCurrency = 'usd',
  email,
  coupon,
  stripeKey,
  stripeKeySCA,
  stripeConnectId,
  stripe,
  enableBeta,
  offerName,
  platform,
  coinbaseEnabled,
  coinbaseCharge,
  domain,
  mediaAssets,
  displayMediaEmbed,
}) => {
  if (couponCurrency === null || couponCurrency === undefined) {
    couponCurrency = 'usd'
  }
  if (currency === null || currency === undefined) {
    currency = 'usd'
  }
  //  console.log("STRIPEFORMELEENTS", this);
  const selected = [styles.planSelectButton, styles.buttonActive]
  const unselected = [styles.planSelectButton, styles.buttonInactive]
  const inputStyles = status === 'Disabled' ? styles.disabledFormInput : styles.formInput
  const payBtnStyles = status === 'Disabled' ? styles.disabledPayBtn : styles.payBtn


  const monthlyStyles = {
    borderColor: buttonColor || '#469CCE',
    color: '#FFF',
    backgroundColor: !yearlySelected ? buttonColor || '#469CCE' : backgroundColor || '#4D4C69',
  }

  const yearlyStyles = {
    borderColor: buttonColor || '#469CCE',
    color: '#FFF',
    backgroundColor: yearlySelected ? buttonColor || '#469CCE' : backgroundColor || '#4D4C69',
  }

  trialPeriod = parseInt(trialPeriod, 10)

  
  // console.log('getPricesElements', period, price)
  const formattedPrices = getFormattedPrices({ customSetting1Enabled, domain, group, period, price, alternatePayment, alternatePaymentAmount, joiningPayment, joiningPaymentAmount, currency}, couponData, yearlySelected)
  
  const currencyUC = formattedPrices.currency.toUpperCase()
  

  // Format purchase button text
  let btnContent = ''
  if (trialPeriod){
    btnContent = 'Start Your Trial'
  } else {
    btnContent = `Pay ${formattedPrices.currentPriceAfterCoupon} ${currencyUC}`
  }

  errors = errors || []

  const formErrors = errors.reduce((acc, error) => {
    acc[error.field] = styles.fieldError
    return acc
  }, {})

  return (
    <div>
      { (openModal && (openModal.modalType === 'success'))
        && <WelcomeModalSCA
          styles={modalStyles}
          group={group}
          channel={channel}
          user={openModal.user}
          subscription={openModal.subscription}
          platform={platform}
        />
      }
      { (openModal && (openModal.modalType === 'error'))
        && <ErrorModal
          styles={modalStyles}
          group={group}
          channel={channel}
          error={errors}
        />
      }
      { (trialPeriod > 0)
        && <div className={styles.trialDetail} style={{ color: textColor || '#fff' }}>
          {`${trialPeriod} day free trial`}
        </div>
      }
      {alternatePayment
        && <div className={styles.paymentSelectorGroup}><span style={monthlyStyles} className={styles.paymentSelector} onClick={makePeriodSwitchHandler('SELECT_MONTHLY')}>Monthly</span><span style={yearlyStyles} className={styles.paymentSelector} onClick={makePeriodSwitchHandler('SELECT_YEARLY')}>Yearly</span></div>
      }

      <MyStoreCheckout
        displayName={displayName}
        preview={preview}
        enableBeta={enableBeta}
        styles={styles}
        url={url}
        currency={currency}
        price={price}
        yearlySelected={yearlySelected}
        errors={errors}
        period={period}
        trialPeriod={trialPeriod}
        openModal={openModal}
        modalStyles={modalStyles}
        channel={channel}
        group={group}
        subscribing={subscribing}
        couponValidating={couponValidating}
        buttonColor={buttonColor}
        textColor={textColor}
        backgroundColor={backgroundColor}
        status={status}
        alternatePayment={alternatePayment}
        alternatePaymentAmount={alternatePaymentAmount}
        joiningPayment={joiningPayment}
        joiningPaymentAmount={joiningPaymentAmount}
        waiveJoiningFeeForYearlyOption={waiveJoiningFeeForYearlyOption}
        convertCode={convertCode}
        couponsEnabled={couponsEnabled}
        verifyEmailEnabled={verifyEmailEnabled}
        useOauthEnabled={useOauthEnabled}
        requireNameEnabled={requireNameEnabled}
        requireAddressEnabled={requireAddressEnabled}
        customSetting1Enabled={customSetting1Enabled}
        customSetting2Enabled={customSetting2Enabled}
        customSetting3Enabled={customSetting3Enabled}
        couponFieldVisible={couponFieldVisible}
        couponValid={couponValid}
        couponId={couponId}
        couponAmountOff={couponAmountOff}
        couponPercentOff={couponPercentOff}
        couponDurationInMonths={couponDurationInMonths}
        couponDuration={couponDuration}
        couponSubmitted={couponSubmitted}
        email={email}
        coupon={coupon}
        submitForm={submitForm}
        formErrors={formErrors}
        inputStyles={inputStyles}
        btnContent={btnContent}
        payBtnStyles={payBtnStyles}
        stripe={stripe}
        customTOS={customTOS}
        offerName={offerName}
        coinbaseEnabled={coinbaseEnabled}
        coinbaseCharge={coinbaseCharge}
        platform={platform}
        formattedPrices={formattedPrices}
        mediaAssets={mediaAssets}
        displayMediaEmbed={displayMediaEmbed}
      />

    </div>
  )
}

export default lifecycle(methods)(StripeFormElements)
